<template>
  <div
    class="h-screen flex w-full vx-row no-gutter items-center justify-center page"
    style="color: #626262"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="card-bg page-card">
          <div v-if="!isLoading">
            <div class="vx-row no-gutter justify-center items-center">
              <img
                src="@/application/assets/images/graphics/sign-up-account.png"
                style="height: 256px;"
              />
            </div>

            <div class="header-text">
              <h2>{{ $l.get("signUp.step-2.header") }}</h2>
              <p>{{ $l.get("signUp.step-2.body") }}</p>
            </div>

            <form @submit.prevent="onSubmitSignUp">
              <vs-input
                v-model="formName"
                :danger="isNameInvalid"
                :danger-text="$l.get('errors.input-required', 'capitalize')"
                :label-placeholder="
                  $l.get('dictionary.full-name', 'capitalize')
                "
              />
              <vs-input
                v-model="formEmail"
                :danger="isEmailWithErrors"
                :danger-text="emailErrorMessage"
                :label-placeholder="$l.get('dictionary.email', 'capitalize')"
              />
              <vs-input
                v-model="formPassword"
                :danger="isPasswordInvalid"
                :danger-text="$l.get('errors.input-required', 'capitalize')"
                :label-placeholder="$l.get('dictionary.password', 'capitalize')"
                type="password"
              />
              <vs-input
                v-model="formPasswordConfirm"
                :danger="isConfirmPasswordInvalid"
                :danger-text="
                  $l.get('errors.input-invalid-password-confirm', 'capitalize')
                "
                :label-placeholder="
                  $l.get('dictionary.password-confirm', 'capitalize')
                "
                type="password"
              />

              <!-- <div style="margin-top: 25px; margin-bottom: 25px;">
              <vs-checkbox>{{ $l.get("login.remember-checkbox") }}</vs-checkbox>
              </div>-->
              <div style="margin-top: 25px;">
                <vs-button
                  color="primary"
                  type="filled"
                  @click="onSubmitSignUp"
                  >{{ $l.get("signUp.step-2.submit-button") }}</vs-button
                >
              </div>
              <input
                type="submit"
                style="position: absolute; left: -9999px; width: 1px; height: 1px;"
                tabindex="-1"
              />
            </form>
          </div>
          <div
            class="animated fadeIn"
            style="text-align: center !important"
            v-else
          >
            <Loader
              :label="$l.get('signUp.step-2.creating-account', 'capitalize')"
            />
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import InitSignUpAccountScreenInteractor from "@/business/sign-up-account/init-sign-up-account-screen";
import SubmitSignUpInteractor from "@/business/sign-up-account/submit-sign-up";
import SignUpAccountScreenController from "@/adapters/controllers/screen-sign-up-account";
import { UserSignUp } from "@/domain/users/models";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "sign-up-account",
  components: { Loader },
  data() {
    return {
      controller: null,
      interactors: {
        initSignUpAccountScreen: null,
        submitSignUp: null
      },
      data: null,
      isNameInvalid: false,
      isEmailInvalid: false,
      isEmailAlreadyInUse: false,
      isPasswordInvalid: false,
      isConfirmPasswordInvalid: false,
      formName: "",
      formEmail: "",
      formPassword: "",
      formPasswordConfirm: "",
      isLoading: false
    };
  },
  beforeRouteEnter(to, from, next) {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (regex.test(to.params.id)) {
      next();
    } else {
      next(false);
    }
  },
  beforeMount() {
    this.controller = this.$injector.get(SignUpAccountScreenController);

    //{ INTERACTORS
    this.interactors.submitSignUp = this.$injector.get(SubmitSignUpInteractor);
    this.interactors.initSignUpAccountScreen = this.$injector.get(
      InitSignUpAccountScreenInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
    this.controller.resetFormErrors = this.resetFormErrors;
    this.controller.setAsNameInvalid = this.setAsNameInvalid;
    this.controller.setAsEmailInvalid = this.setAsEmailInvalid;
    this.controller.setAsEmailAlreadyInUse = this.setAsEmailAlreadyInUse;
    this.controller.setAsPasswordInvalid = this.setAsPasswordInvalid;
    this.controller.setAsConfirmPasswordInvalid = this.setAsConfirmPasswordInvalid;
  },
  mounted() {
    this.interactors.initSignUpAccountScreen.handle(this.$context);
  },
  computed: {
    isEmailWithErrors() {
      return this.isEmailInvalid || this.isEmailAlreadyInUse;
    },
    emailErrorMessage() {
      if (this.isEmailAlreadyInUse) {
        return this.$l.get("errors.input-email-already-in-use", "capitalize");
      } else if (this.isEmailInvalid) {
        return this.$l.get("errors.input-invalid-email", "capitalize");
      } else {
        return "";
      }
    }
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    resetFormErrors() {
      this.isNameInvalid = false;
      this.isEmailInvalid = false;
      this.isEmailAlreadyInUse = false;
      this.isPasswordInvalid = false;
      this.isConfirmPasswordInvalid = false;
    },
    setAsNameInvalid() {
      this.isNameInvalid = true;
    },
    setAsEmailInvalid() {
      this.isEmailInvalid = true;
    },
    setAsEmailAlreadyInUse() {
      this.isEmailAlreadyInUse = true;
    },
    setAsPasswordInvalid() {
      this.isPasswordInvalid = true;
    },
    setAsConfirmPasswordInvalid() {
      this.isConfirmPasswordInvalid = true;
    },
    onSubmitSignUp() {
      const birthDate = this.$context.birthDate;
      const year = birthDate.getUTCFullYear();
      const month = birthDate.getUTCMonth() + 1;
      const day = birthDate.getUTCDate();

      this.interactors.submitSignUp.handle(
        new UserSignUp(
          this.formName.trim(),
          this.formEmail.trim(),
          this.formPassword,
          this.$route.params.id,
          this.$l.lang,
          `${month}-${day}-${year}`
        ),
        this.formPasswordConfirm
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.card-bg .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}
.card-bg .vs-button {
  width: 100% !important;
}
.header-text {
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;
}
.inputs .vs-input {
  margin: 15px;
}
</style>
